import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Blogs() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogsResponse, authorsResponse] = await Promise.all([
          fetch('https://ui-learning-81da6-default-rtdb.firebaseio.com/blogs.json'),
          fetch('https://ui-learning-81da6-default-rtdb.firebaseio.com/authors.json')
        ]);

        if (!blogsResponse.ok || !authorsResponse.ok) {
          throw new Error('Error fetching data');
        }

        const blogsData = await blogsResponse.json();
        const authorsData = await authorsResponse.json();

        const blogsArray = Object.entries(blogsData).map(([id, blog]) => ({
          id,
          ...blog,
          authorUsername: authorsData[blog.authorId]?.username || 'Unknown Author',
        }));

        setData(blogsArray);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      {error && <p className="alert alert-danger">{error}</p>}
      {data.length === 0 ? (
        <p>Loading blogs...</p>
      ) : (
        data.map((blog) => (
          <div className="card" key={blog.id}>
            <div className="card-header">
              <p className="text-uppercase fw-bold">{blog.authorUsername}</p>
            </div>
            <div className="card-body">
              <h5 className="card-title">{blog.title}</h5>
              <p className="card-text">{blog.content.slice(0, 100)}...</p>
              <Link to={`blog/${blog.id}`} className="btn btn-dark">Read Full Blog</Link>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
