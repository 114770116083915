import React from 'react'
import './AdminCSS/style.css';
import { Link } from 'react-router-dom';

export default function MainBody() {
    return (
        <div className="container-flued px-4 w-100">
          <h2 className=' d-flex justify-content-start text-white mb-5'>Dashboard</h2>
            <div className="row mb-5 d-flex justify-content-center">
                <div className="col-8 d-flex justify-content-between w-100">
                    <div style={{ minHeight: '140px', minWidth: '250px' }} className="card mainCard1">
                        <div className="main">
                            <div className='d-flex ps-3 pt-3'><p className=' float-start'>Total Users</p></div>
                            <div className='w-75'><h1 className=' d-flex  justify-content-around'><i className="fa-solid fa-users"></i> 1500</h1></div>
                            <div><Link className='w-50 text-white opacity-50 mt-3'>View all users<i className='fa-solid fa-chevron-right'></i></Link></div>
                        </div>
                    </div>
                    <div style={{ minHeight: '140px', minWidth: '250px' }} className="card mainCard2">
                        <div className="main">
                            <div className='d-flex ps-3 pt-3'><p className=' float-start'>Total Blogs</p></div>
                            <div className='w-75'><h1 className=' d-flex  justify-content-around'><i className="fa-brands fa-blogger-b"></i> 18200</h1></div>
                            <div><Link className='w-50 text-white opacity-50 mt-3'>View all blogs<i className='fa-solid fa-chevron-right'></i></Link></div>
                        </div>
                    </div>
                    <div style={{ minHeight: '140px', minWidth: '250px' }} className="card mainCard3">
                        <div className="main">
                            <div className='d-flex ps-3 pt-3'><p className=' float-start'>Total Authors</p></div>
                            <div className='w-75'><h1 className=' d-flex  justify-content-around'><i className="fa-solid fa-user-tie"></i> 1500</h1></div>
                            <div><Link className='w-50 text-white opacity-50 mt-3'>View all authors<i className='fa-solid fa-chevron-right'></i></Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <h5 className='d-flex justify-content-start text-white mb-3'>New Blogs & Details</h5>
                <table class="table table-dark rounded rounded-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Blogs</th>
                            <th scope="col">Authors</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Ma blog title ideas using your target keywords with ...</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jac blog title ideas using your target keywords with ...</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Lar blog title ideas using your target keywords with ...</td>
                            <td>the Bird</td>
                            <td>@twitter</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th scope="row">4</th>
                            <td>Ma blog title ideas using your target keywords with ...</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Jac blog title ideas using your target keywords with ...</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Lar blog title ideas using your target keywords with ...</td>
                            <td>the Bird</td>
                            <td>@twitter</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th scope="row">7</th>
                            <td>Ma blog title ideas using your target keywords with ...</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Jac blog title ideas using your target keywords with ...</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Lar blog title ideas using your target keywords with ...</td>
                            <td>the Bird</td>
                            <td>@twitter</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
