import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const AuthorPanelAuth = () => {
    const navigate = useNavigate(); // To programmatically navigate
    const [Comment, setComment] = useState('');
    const [Name, setName] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // Validation function
    const validateForm = () => {
        if (!Comment || !Name) {
            setAlertMessage("Please fill in all required fields.");
            setShowAlert(true);
            return false;
        }
        setShowAlert(false);
        return true;
    };

    // Function to post the comment to Firebase using fetch
    const postComment = async () => {
        const commentData = {
            comment: Comment,
            name: Name,
            timestamp: new Date().toISOString(),
        };

        try {
            const response = await fetch('https://ui-learning-81da6-default-rtdb.firebaseio.com/blogs.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(commentData),
            });

            if (!response.ok) {
                throw new Error("Failed to post comment");
            }

            setAlertMessage("Comment posted successfully!");
            setShowAlert(true);
            setComment(''); // Clear the input fields after successful submission
            setName('');
        } catch (error) {
            setAlertMessage("Error posting comment. Please try again.");
            setShowAlert(true);
            console.error("Error posting comment:", error);
        }
    };

    const handleLoginClick = (e) => {
        e.preventDefault(); // Prevent form default submission
        if (validateForm()) {
            postComment();
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center">
            <Row className="w-100" style={{ maxWidth: '400px' }}>
                <Col>
                    <div className="bg-light p-4 shadow rounded">
                        <Form>
                            {showAlert && <Alert variant="danger">{alertMessage}</Alert>}
                            <Form.Group className="mb-3" controlId="formComment">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Write comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Your name"
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button
                                variant="dark"
                                type="submit"
                                className="w-100"
                                onClick={handleLoginClick} // Call handleLoginClick on click
                            >
                                Post Comment
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AuthorPanelAuth;
