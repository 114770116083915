import './App.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import ViewMyBlogs from './components/AuthorPanel/ViewMyBlogs';
import ViewAuthors from './components/AdminPanel/ViewAuthors';
import CreateAuthor from './components/AdminPanel/CreateAuthor';
import HomeAdmin from './components/AdminPanel/HomeAdmin';
import HomeAuthor from './components/AuthorPanel/HomeAuthor';
import MainBodyAuthor from './components/AuthorPanel/MainBodyAuthor';
import ViewBlogs from './components/AdminPanel/ViewBlogs';
import CreateBlog from './components/AuthorPanel/CraeteBlog';
import Authentication from './components/AuthorPanel/Authentication';
import Approvals from './components/AdminPanel/Approvals';
import MainBody from './components/AdminPanel/MainBody';
import Blogs from './components/UserPanel/Blogs';
import Blog from './components/UserPanel/Blog';
import Home from './components/UserPanel/Home';

function App() {
  return (
    <>

    <Outlet></Outlet>

    <Routes>


      {/* Admin Routes */}
      <Route path='/admin' element={<HomeAdmin/>}>
        <Route index element={<MainBody/>} />
        <Route path='allBlogs' element={<ViewBlogs/>} />
        <Route path='allauthors' element={<ViewAuthors/>} />
        <Route path='createAuthor' element={<CreateAuthor/>} />
        <Route path='createBlog' element={<CreateBlog/>} />
        <Route path='approval' element={<Approvals/>} />
      </Route>


      {/* Author Routes */}
      <Route path='/author' element={<HomeAuthor/>}>
        <Route index element={<MainBodyAuthor/>}/>
        <Route path='/author/allMyBlogs' element={<ViewMyBlogs/>} />
        <Route path='/author/createBlog' element={<CreateBlog/>} />
      </Route>


      {/* Author Routes */}
      <Route path='/' element={<Home></Home>}>
        <Route index element={<Blogs></Blogs>}/>
        <Route path='blog/:id' element={<Blog/>}/>
        {/* <Route path='' element={}/> */}
      
      </Route>
      <Route path='*' />

    </Routes>
    
    </>

  )
}

export default App;
