import React, { useState } from 'react'
import { json } from 'react-router-dom';

export default function CreateAuthor() {
  let [author, setAuthor] = useState('')
  function CreateAuthor() {
    let AdminConfirmed = true
    if (AdminConfirmed) {
      let NameAuthor = {
        name: author
      }
       fetch('https://ui-learning-81da6-default-rtdb.firebaseio.com/Blogs.json',{
        method:'POST',
        headers:{
          'Content-Type':'Application/json'
        },
        body:JSON.stringify(NameAuthor)
       })
       .then(response=>{
        if (!response.ok) {
          throw  alert("Network response was not ok")
        }
        return response.json()
       })
      alert("A new author added.");
    } else {
      // Code to execute if the user clicked "Cancel"
      console.log("User canceled action.",author);
    }


  }
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-8">
          <h3 className='text-white'>Create an Author</h3>
          <div className="pt-5">
            <input type="text" placeholder='Enter author name' className='p-2 w-75 rounded border-0' onChange={(e) => { setAuthor(e.target.value) }} />
            <button className='btn btn-danger w-75 mt-5' onClick={() => { CreateAuthor() }}>Create Author</button>
          </div>

        </div>
      </div>
    </div>
  )
}
