import React from 'react'
import Nav from '../Nav'
import Blogs from './Blogs'
import Footer from '../Footer'


export default function Home() {
  return (
    <div className="main">
      <Nav></Nav>
        <Blogs  style={{minHeight:'100hv'}}></Blogs>
      <Footer></Footer>
    </div>
  )
}
