export let AdminMenu = [
    {
        title:'Home',
        icon: 'fa-solid fa-house',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin'
    },
    {
        title:'All Blogs',
        icon: 'fa-brands fa-blogger-b',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin/allBlogs'
    },
    {
        title:'All Authors',
        icon: 'fa-solid fa-user-tie',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin/allauthors'
    },
    {
        title:'Create author',
        icon: 'fa-solid fa-user-plus',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin/createAuthor'
    },
    {
        title:'Create blog',
        icon: 'fa-solid fa-user-plus',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin/createBlog'
    },
    {
        title:'Approvals',
        icon: 'fa-solid fa-clock',
        arrow : 'fa-solid fa-chevron-right',
        path:'/admin/approval'
    }
]

export let AuthorMenu = [
    {
        title:'Home',
        icon: 'fa-solid fa-house',
        arrow : 'fa-solid fa-chevron-right',
        path:'/author'
    },
    {
        title:'My All Blogs',
        icon: 'fa-brands fa-blogger-b',
        arrow : 'fa-solid fa-chevron-right',
        path:'/author/allMyBlogs'
    },
    {
        title:'Create blog',
        icon: 'fa-solid fa-user-plus',
        arrow : 'fa-solid fa-chevron-right',
        path:'/author/createBlog'
    }
]

