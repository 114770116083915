import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import data, { AdminMenu } from '../Assets/data'
import { Modal, Button, Form } from 'react-bootstrap';
import Authentication from './AuthorPanel/Authentication'

export default function Nav() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
        console.log("Form submitted!");
        handleClose(); // Close the modal after submit
    };


    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="#"><h1><i class="fa-solid fa-feather-pointed"></i></h1></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link variant="primary" onClick={handleShow} className="nav-link active" aria-current="page" href="#">Create Blog</Link>
                        </li>
                        <li className="nav-item">
                            <Link variant="primary" onClick={handleShow} className="nav-link active" aria-current="page" href="#">My Blogs</Link>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <Link className='btn btn-danger' to=''>Admin Panel</Link>
                        {/* Button to trigger the modal */}
                        <Button className="btn btn-success ms-3" variant="primary" onClick={handleShow}>
                            SignUp
                        </Button>




                        {/* Modal for author logins */}
                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Login to author</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Authentication />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </nav>

    )
}
