import React from 'react';
import './HardwareCard.css'; // Assume external CSS for styling

export default function HardwareMonitoringCard({ cpuUsage, memoryUsage, diskSpace }) {
  return (
    <div className="hardware-card p-3 shadow-sm">
      <h6 className="card-title mb-3 fw-bold">Hardware Monitoring</h6>
      
      {/* CPU Usage */}
      <div className="metric">
        <h6>CPU Usage</h6>
        <div className="progress mb-2">
          <div 
            className="progress-bar bg-success" 
            role="progressbar" 
            style={{ width: `${cpuUsage}%` }}
            aria-valuenow={cpuUsage}
            aria-valuemin="0"
            aria-valuemax="100">
            {cpuUsage}%
          </div>
        </div>
      </div>
      
      {/* Memory Usage */}
      <div className="metric">
        <h6>Memory Usage</h6>
        <div className="progress mb-2">
          <div 
            className="progress-bar bg-info" 
            role="progressbar" 
            style={{ width: `${memoryUsage}%` }}
            aria-valuenow={memoryUsage}
            aria-valuemin="0"
            aria-valuemax="100">
            {memoryUsage}%
          </div>
        </div>
      </div>
      
      {/* Disk Space */}
      <div className="metric">
        <h6>Disk Space</h6>
        <div className="progress mb-2">
          <div 
            className="progress-bar bg-warning" 
            role="progressbar" 
            style={{ width: `${diskSpace}%` }}
            aria-valuenow={diskSpace}
            aria-valuemin="0"
            aria-valuemax="100">
            {diskSpace}%
          </div>
        </div>
      </div>
    </div>
  );
}
