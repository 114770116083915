import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const AuthorPanelAuth = () => {
  const navigate = useNavigate(); // To programmatically navigate
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // Validation function
  const validateForm = () => {
    if (!email || !password) {
      setAlertMessage("Please fill in all required fields.");
      setShowAlert(true);
      return false;
    }
    setShowAlert(false);
    return true;
  };

  const handleLoginClick = (e) => {
    if (!validateForm()) {
      e.preventDefault(); // Prevent navigation if validation fails
      return;
    }
    navigate('/author'); // Navigate to author page if validation succeeds
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Row className="w-100" style={{ maxWidth: '400px' }}>
        <Col>
          <div className="bg-light p-4 shadow rounded">
            <Form>
              {showAlert && <Alert variant="danger">{alertMessage}</Alert>}
              <Form.Group className="mb-3" controlId="formLoginEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLoginPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                variant="btn btn-secondary"
                type="submit"
                className="w-100"
                onClick={handleLoginClick} // Call handleLoginClick on click
              >
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthorPanelAuth;
