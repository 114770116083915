import React from 'react'

export default function ViewBlogs() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
        <h5 className='d-flex justify-content-start text-white mb-3'>All Blogs & Details</h5>
                <table class="table table-dark rounded rounded-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Blogs</th>
                            <th scope="col">Authors</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Ma blog title ideas using your target keywords with ...</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
            </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}
