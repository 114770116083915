import React, { useState } from 'react';

export default function CreateBlog() {
  const [blogTitle, setBlogTitle] = useState('');
  const [content, setContent] = useState('');
  const [authorSelected, setAuthorSelected] = useState('');

  async function createBlogFunc(blogData) {
    try {
      const response = await fetch('https://ui-learning-81da6-default-rtdb.firebaseio.com/blogs.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(blogData)
      });

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      const data = await response.json();
      console.log('Blog created with ID:', data.name); // Firebase returns a unique ID for the new blog
    } catch (error) {
      console.error('Error creating blog:', error);
    }
  }

  const handleCreateBlog = () => {
    const newBlog = {
      title: blogTitle,
      content: content,
      authorId: authorSelected,
      timestamp: Date.now(),
      status: 'draft'
    };

    createBlogFunc(newBlog);
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-8">
          <h3 className="text-white">Create a new blog</h3>
          <div className="pt-5">
            <input
              type="text"
              placeholder="Enter blog title"
              className="p-2 w-75 rounded border-0"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
            <textarea
              className="w-75 border-0 rounded mt-3"
              cols="60"
              rows="10"
              placeholder="Enter blog description"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></textarea>
            <select
              value={authorSelected}
              onChange={(e) => setAuthorSelected(e.target.value)}
              className="w-75 border-0 rounded mt-3"
            >
              <option value="">You are Author</option>
              <option value="authorId1">Author 1</option>
              <option value="authorId2">Author 2</option>
              {/* Add other authors here */}
            </select>
            <button className="btn btn-secondary w-75 mt-5" onClick={handleCreateBlog}>
              Create Blog
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
