import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommentForm from './CommentForm';

export default function Blog() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`https://ui-learning-81da6-default-rtdb.firebaseio.com/blogs/${id}.json`);
        if (!response.ok) throw new Error('Error fetching blog data');
        const data = await response.json();
        setBlog(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchBlogData();
  }, [id]);

  const handleDelete = async () => {
    try {
      const response = await fetch(`https://ui-learning-81da6-default-rtdb.firebaseio.com/blogs/${id}.json`, {
        method: 'DELETE'
      });
      if (!response.ok) throw new Error('Error deleting blog');
      navigate('/'); // Redirect after deletion
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container">
      {error && <p className="alert alert-danger">{error}</p>}
      {blog ? (
        <div className="card">
          <div className="card-header">{blog.authorUsername || 'Unknown Author'}</div>
          <div className="card-body">
            <h5 className="card-title">{blog.title}</h5>
            <p className="card-text">{blog.content}</p>
            <Button className="btn btn-danger" onClick={handleDelete}>Delete</Button>
            <Button className="btn btn-dark ms-3" onClick={() => setShow(true)}>Comment</Button>

            <Modal show={show} onHide={() => setShow(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Leave a Comment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CommentForm />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ) : (
        <p>Loading blog details...</p>
      )}
    </div>
  );
}
