import React, { useState } from 'react';
import './AdminCSS/style.css';
import { AdminMenu } from '../../Assets/data';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import ViewBlogs from './ViewBlogs';
import ViewAuthors from './ViewAuthors';
import CreateAuthor from './CreateAuthor';
import Approvals from './Approvals';
import MainBody from './MainBody';
import HardwareMonitoringCar from '../../Assets/HardwareMonitoringCard'
import CreateBlog from './CraeteBlog';
// import CraeteBlog from './CraeteBlog';
// import BlogComments from './BlogComments';

export default function HomeAdmin() {
  let [Active, setActive] = useState(0)



  return (
    <div style={{ minHeight: '100vh' }} className="container-flued d-flex justify-content-center adminBg py-3">
      <div style={{ minWidth: '98%' }} className="row d-flex justify-content-between p-0 mx-1 adminBgLayer border border-1 rounded-5">

        {/* Left Sidebar */}
        <div style={{ width: '17%' }} className="navbar nav border rounded-start-5 rounded-top-5 bg-white text-dark">
          <div className="logo d-flex justify-content-center ms-3">
            <Link className='text-dark' style={{ textDecoration: 'none' }} to="/admin">
              <i class="fa-solid fa-feather-pointed fs-1"></i><span className=' fs-4 fw-bolder'>Writrly (Admin)</span>
            </Link>
          </div>
          {AdminMenu.map((menu, index) => (
            <Link style={{ textDecoration: 'none' }} to={menu.path} className={Active === index ? 'main w-100 px-1 border selected rounded-end-4' : 'main w-100 px-1 border text-dark rounded-end-4'} onClick={() => setActive(index)}>
              <div key={index} style={{ height: '6%' }}>
                <div className="menu d-flex justify-content-around">
                  <span className="Icon fs-4"><i className={`${menu.icon} text-start`}></i></span>
                  <span className='fs-4 float-end'>{menu.title}</span>
                  <span className='fs-4 float-end'><i className={menu.arrow}></i></span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Center Section */}
        <div style={{ width: '65%' }} className="mt-5">

          <Outlet></Outlet>
        </div>

        {/* Right Sidebar */}
        <div style={{ width: '15%' }} className="navbar border rounded-end-5 rounded-top-5 bg-secondary">
          <div className="main w-100 h-100">
            <div className='h-50 border rounded-top-5 bg-white pt-2 text-danger'>
              <div className="heading">
                <h5>Recent Activity</h5>
              </div>
              <div className="body">
                <div className="main d-flex bg-secondary rounded my-3 p-1">
                  <div className="dp d-flex justify-content-center w-25 h-25 rounded-circle bg-danger">
                    <h1 className=' text-white'>A</h1>
                  </div>
                  <div className="details w-75">
                    <div className="name d-flex justify-content-start ms-2">
                      <h5 className=' text-white'>Ahmed</h5>
                    </div>
                    <div className="time">
                      <p className=' text-white opacity-50 d-flex justify-content-start ms-2'>39 minutes ago</p>
                    </div>
                  </div>
                </div>
                <div className="main d-flex bg-secondary rounded my-3 p-1">
                  <div className="dp d-flex justify-content-center w-25 h-25 rounded-circle bg-danger">
                    <h1 className=' text-white'>H</h1>
                  </div>
                  <div className="details w-75">
                    <div className="name d-flex justify-content-start ms-2">
                      <h5 className=' text-white'>Hadid</h5>
                    </div>
                    <div className="time">
                      <p className=' text-white opacity-50 d-flex justify-content-start ms-2'>53 minutes ago</p>
                    </div>
                  </div>
                </div>
                <div className="main d-flex bg-secondary rounded my-3 p-1">
                  <div className="dp d-flex justify-content-center w-25 h-25 rounded-circle bg-danger">
                    <h1 className=' text-white'>S</h1>
                  </div>
                  <div className="details w-75">
                    <div className="name d-flex justify-content-start ms-2">
                      <h5 className=' text-white'>Salman</h5>
                    </div>
                    <div className="time">
                      <p className=' text-white opacity-50 d-flex justify-content-start ms-2'>58 minutes ago</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-white rounded-top-5 pt-5'>
                <HardwareMonitoringCar cpuUsage={45} memoryUsage={75} diskSpace={60} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
